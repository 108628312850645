import React from 'react'
import { array, func, oneOf, string } from 'prop-types'

import XCircle from '@creditas/icons/dist/action/XCircle'
import AlertTriangle from '@creditas/icons/dist/alert/AlertTriangle'
import CheckCircle from '@creditas/icons/dist/notification/CheckCircle'
import { Typography } from '@creditas/typography'

import {
  IconWrapper,
  InputCheckbox,
  LegendWrapper,
  Wrapper,
} from './Checkbox.style'

const propTypes = {
  'data-testid': string,
  helperText: string,
  id: string.isRequired,
  label: string,
  name: string.isRequired,
  onChange: func,
  options: array,
  selectedOptions: array,
  variant: oneOf(['success', 'warning', 'danger', 'default']),
}

const defaultProps = {
  'data-testid': 'checkbox',
}

const iconVariants = {
  danger: AlertTriangle,
  success: CheckCircle,
  warning: XCircle,
}

const Checkbox = React.forwardRef((props, ref) => {
  const {
    'data-testid': dataTestid,
    helperText,
    id,
    label,
    name,
    onChange,
    options,
    selectedOptions,
    variant: statusVariant,
  } = props

  const optionsWithSelecteds = options?.map(option => {
    option.selected = selectedOptions?.some(
      selectedOption => selectedOption === option.value,
    )

    return option
  })

  const StatusIcon = iconVariants[statusVariant]

  return (
    <Wrapper as="fieldset" data-testid={dataTestid}>
      <LegendWrapper>
        {label && (
          <Typography
            data-testid={`${dataTestid}-legend`}
            as="legend"
            variant="labelForm"
          >
            {label}
          </Typography>
        )}

        {StatusIcon && (
          <IconWrapper>
            <StatusIcon
              data-testid={`${dataTestid}-status-icon`}
              color={statusVariant}
            />
          </IconWrapper>
        )}
      </LegendWrapper>

      {optionsWithSelecteds?.map(
        ({ label: checkboxLabel, selected, value }, index) => (
          <InputCheckbox
            key={`CreditasCheckbox-${name}-${index}`}
            id={`${id}-${name}-${index}`}
            name={name}
            value={value}
            defaultChecked={selected}
            onChange={onChange}
            ref={ref}
            boxed
            fullWidth
          >
            {checkboxLabel}
          </InputCheckbox>
        ),
      )}

      {helperText && (
        <Typography variant="supportForm" color={statusVariant}>
          {helperText}
        </Typography>
      )}
    </Wrapper>
  )
})

Checkbox.displayName = 'Checkbox'
Checkbox.propTypes = propTypes
Checkbox.defaultProps = defaultProps

export default Checkbox
