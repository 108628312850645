import { Checkbox } from '@creditas/checkbox'
import { styled } from '@creditas/stylitas'

const Wrapper = styled.div`
  margin-bottom: 16px;
  width: 100%;
`

const LegendWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`

const IconWrapper = styled.div`
  margin-top: -12px;
  margin-bottom: -12px;
`

const InputCheckbox = styled(Checkbox)`
  margin-bottom: 8px;
`

export { Wrapper, LegendWrapper, IconWrapper, InputCheckbox }
